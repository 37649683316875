import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

const TreatmentHeroVideo = ({
  img,
  imgAlt,
  heading1,
  text,
  bgColour,
  headingSmall,
  heading2,
  objPos,
  videoUrl,
  altText,
  ctaButton,
}) => {
  const videoAlt = altText || "Sorry, your browser doesn't support videos.";
  return (
    <Container className="px-0 mx-0 my-5 mb-lg-7" fluid>
      <Row>
        <Col className="text-center">
          <p className="text-text-secondary jost-bold fs-6">TREATMENTS</p>
          <h1 className=" display-4 mb-5">{heading1}</h1>
        </Col>
      </Row>
      <Row className={`${bgColour} align-items-center`}>
        <Col className="ps-lg-5 mb-5 mb-lg-0" lg={6}>
          <video style={{ width: "100%" }} controls>
            <source src={videoUrl} type="video/mp4" />
            <p>{videoAlt}</p>
          </video>
        </Col>
        <Col className="pe-xl-8 px-4 text-center text-lg-start px-md-5" lg={6}>
          <p className="text-accent jost-bold fs-6">{headingSmall}</p>
          <h2 className="text-text-secondary display-5 mb-4">{heading2}</h2>
          {text}
          {ctaButton && ctaButton.url && (
            <Button
              className="text-white py-3 px-5 w-100 w-md-auto"
              variant="accent"
              href={ctaButton?.url}
              target={ctaButton?.target ?? "_blank"}
              rel="noreferrer"
            >
              {ctaButton?.title}
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default TreatmentHeroVideo;
